@import "./theme-settings.scss";

.add-new-styles-here {
  color: blue;
}

.mini-header.horizontal img {
  margin-bottom: 30px;
  @media screen and (min-width: 768px) {
    margin-bottom: 40px;
  }
}
.main-navbar .logo span {
  display: none;
  @media screen and (min-width: 1200px) {
    // display: block;
  }
}

.languageSelector .languageButton {
  font-size: 14px !important;
  @media screen and (min-width: 1200px) {
    font-size: 17px !important;
  }
}
